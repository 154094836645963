import React from 'react'
import PropTypes from 'prop-types'
import { YoutubeWrapper } from '../styles/common'

export default function YoutubeVideo( { id }) {

const src = `https://www.youtube.com/embed/${id}`

return(
  <YoutubeWrapper>
     {id && 
     <iframe src={src}
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen>
        </iframe>
    }
  </YoutubeWrapper>
)}

YoutubeVideo.defaultProps = {
    id: `RpgHMTIxjgU`,
  }
  YoutubeVideo.propTypes = {
    id: PropTypes.string.isRequired,
}