import React from 'react'
import PropTypes from 'prop-types'
import { ButtonStyle } from '../styles/buttons'

export default function Button({ title, handleClick, answer, disabled, outline, small }) {
    return(
    <ButtonStyle onClick={ () => handleClick(answer) } disabled={disabled} outline={outline} small={small} >
        {title}
    </ButtonStyle>
    )
}

Button.defaultProps = {
    title: 'Add a text...',
    disabled: false,
    outline: false,
    small: false
  };

  Button.propTypes = {
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    answer: PropTypes.object,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    small: PropTypes.bool,
}