import React from 'react'
import PropTypes from 'prop-types'
import { H1Wrap } from '../styles/common'

const H1 = ({ title, subtitle, border }) => (
  <H1Wrap border={border}>
      {subtitle &&
      <p>{ subtitle }</p>
      }
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
  </H1Wrap>
)

export default H1

H1.defaultProps = {
    border: false,
  }

H1.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    border: PropTypes.bool,
}