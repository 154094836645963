import React from 'react'
import PropTypes from 'prop-types'
import { FacebookButtonStyle } from '../styles/buttons'

export default function FacebookButton({ title, handleClick , small }) {
    return(
    <FacebookButtonStyle onClick={ () => handleClick() } small={small} target="_blank">
        {title}
    </FacebookButtonStyle>
    )
}

FacebookButton.defaultProps = {
    title: 'Add a text...',
    small: false
  };

  FacebookButton.propTypes = {
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    small: PropTypes.bool,
}