import styled from 'styled-components';
import breakpoint, {map} from 'styled-components-breakpoint'


// button styles
export const ButtonStyle = styled.button`

  ${tw`px-12 py-1 mb-4 border-0 text-black rounded inline-block m-auto transition-all duration-200 ease-in-out cursor-pointer`};
  background: ${props => props.theme.colors.buttonColor};
  transition: all 200ms ease-in;
  &:hover,
  &:focus {
    ${tw`transition-all duration-200 ease-in-out`};
    
    background: ${props => props.theme.colors.buttonColorHover};
  }

  border: ${props => props.outline === true ? `2px solid ${props.theme.colors.buttonBorderColor}` : "none" };
  background: ${props => props.outline === true ? "none" : props.theme.colors.buttonColorHover };
  color: ${props => props.outline === true ? props.theme.colors.buttonColorSecondary : props.theme.colors.buttonColor };
  ${breakpoint('md')`
      font-size: ${props => props.small === true ? '20px' : '30px' }
`}

${breakpoint('md')`
      padding: ${props => props.small === true ? '7px 40px' : '7px 60px' }
`}

&:disabled {
      ${tw`border-2 border-solid border-grey-darkest cursor-not-allowed bg-grey-darkest text-grey-dark`};
  }
`

// FacebookButton
export const FacebookButtonStyle = styled.a`

  ${tw`px-12 py-1 mb-4 border-0 text-black rounded-sm inline-block m-auto`};
  background: #334F8D;
  border: 2px solid #334F8D;
  transition: all 200ms ease-in-out;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
    color: #fff !important;
  }

 ${breakpoint('md')`
      font-size: ${props => props.small === true ? '20px' : '30px' }
`}

${breakpoint('md')`
      padding: ${props => props.small === true ? '7px 40px' : '7px 60px' }
`}


`

export const ButtonsWrapper = styled.div`
    ${tw`px-8 py-8 text-center mb-6`};
    > * {
        margin: 0 1rem;
        margin-bottom: 1rem;
    }
    ${breakpoint('md')`
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`