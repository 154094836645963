import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import MainWrap from '../components/mainwrap'
import Button from '../components/button'
import FacebookButton from '../components/facebookbutton'
import YoutubeVideo from '../components/youtubevideo'
import H1 from '../components/h1'
import { useMutation } from 'react-apollo'
import { UPDATE_SHARED_MUTATION } from '../graphql/mutations'
import { ButtonsWrapper } from '../styles/buttons'

export default function ThanksPage() {

const [updateSharedStatus] = useMutation(UPDATE_SHARED_MUTATION)
const facebookUrl = `https://facebook.com/sharer.php?display=page&u=https://kampanje.spilleriet.no/`

const handleFacebookClick = () => {
    
    const id = getUserId();
    if (id !== undefined || id !== null) { updateSharedStatus({variables: { id }})
        .then((res => { navigate(facebookUrl) }))
        .catch((err) => console.log('err',err ));
    };
    
}

const getUserId = () => { localStorage.getItem('userId') }

return(
  <Layout>
    <MainWrap>
    <H1 title="Du er nå med i trekningen, og kan vinne iPhone 12 og Lenovo nettbrett" subtitle="Gratulerer!"></H1>
    <YoutubeVideo id="3diaq6LQYMc"></YoutubeVideo>
    <ButtonsWrapper>
        <FacebookButton title="Del på facebook" handleClick={ () => { handleFacebookClick() } } small={true}></FacebookButton>
        <Button title="Spill bingo i dag" handleClick={ () => { navigate("https://spilleriet.no") }} answers={null} outline={true} small={true}></Button>
    </ButtonsWrapper>
    </MainWrap>
    </Layout>
)}